import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/shared/Navbar';
import Test from './components/shared/Test';
import AddLocation from './pages/AddLocation';
import AddVehicle from './pages/AddVehicle';
import Home from './pages/Home';
import PlanATour from './pages/PlanATour';
import './App.css';
import TourList from './pages/TourList';
import SingleTour from './pages/SingleTour';
import Login from './pages/Login';
import AllLocations from './pages/AllLocations';
import EditLocation from './pages/EditLocation';
import AddLocationType from './pages/AddLocationType';
import EditSingleTour from './pages/EditSingleTour';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="*" element={<h1>404</h1>} />
          <Route path="/add-location" element={<AddLocation />} />
          <Route path="/add-vehicle" element={<AddVehicle />} />
          <Route path="/plan-a-tour" element={<PlanATour />} />
          <Route path="/test" element={<Test />} />
          <Route path="/tour-list" element={<TourList />} />
          <Route path="/tour-list/:tourId" element={<SingleTour />} />
          <Route path="/tour-list/edit/:tourId" element={<EditSingleTour />} />
          <Route path="/all-locations" element={<AllLocations />} />
          <Route path="/edit-location/:locationId" element={<EditLocation />} />
          <Route path="/add-location-type" element={<AddLocationType />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
