import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Login = () => {

    const navigate = useNavigate();

    // create a loading state
    const [loading, setLoading] = useState(false);


    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: '',
    });

    // hande email change
    const handleEmail = (e) => {
        setLoginInfo({ ...loginInfo, email: e.target.value });
    };

    // handle password change
    const handlePassword = (e) => {
        setLoginInfo({ ...loginInfo, password: e.target.value });
    };

    console.log(loginInfo);


    const handleLogin = (e) => {
        setLoading(true);
        e.preventDefault();

        // vlidate the form
        if (loginInfo.email === '' || loginInfo.password === '') {
            Swal.fire({
                title: 'Error!',
                text: 'Please fill up the form',
                icon: 'error',
                showConfirmButton: false,
                timer: 1500
            })
            setLoading(false);

        } else {
            fetch('https://api.lekkerwinetours.com/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginInfo)
            })

                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    setLoading(false);
                    // save the token in local storage
                    const token = data?.authorisation?.token;
                    const user = data?.user;
                    console.log(user);
                    // redirect to dashboard
                    if (data?.status === 'success') {
                        //navigate('/home');
                        Swal.fire({
                            icon: 'success',
                            title: 'Login Successful',
                            text: 'Welcome to Lekker Wine Tours',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        // save the token in local storage
                        localStorage.setItem('token', token);
                        // Save the user in local storage
                        localStorage.setItem('user', JSON.stringify(user));
                        window.location.reload();
                    } else {
                        Swal.fire({
                            title: 'Unauthorized!',
                            text: 'Wrong email or password',
                            icon: 'error',
                            showConfirmButton: 'OK',
                        })
                        setLoading(false);
                        // clear the form
                        e.target.reset();
                        setLoginInfo({
                            email: '',
                            password: '',
                        });

                    }


                })

        }



    };

    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/home');
        }
    }, []);




    return (
        <div>
            <div>
                <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100 sm:px-6 lg:px-8">
                    <div className="w-full max-w-md px-4 py-8 bg-white border-0 shadow-lg sm:rounded-3xl">
                        <h1 className="text-2xl font-bold text-center">Login</h1>
                        <form onSubmit={(e) => handleLogin(e)} className="mt-6">
                            <div className="relative z-0 w-full mb-5">
                                <input onChange={(e) => handleEmail(e)} id='email' type="email" name="email" placeholder="Email" required className="w-full h-12 px-3 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border rounded-lg shadow-sm appearance-none focus:outline-none focus:shadow-outline" />
                            </div>
                            <div className="relative z-0 w-full mb-5">
                                <input onChange={(e) => handlePassword(e)} id='password' type="password" name="password" required placeholder="Password" className="w-full h-12 px-3 py-2 text-base text-gray-700 placeholder-gray-400 bg-white border rounded-lg shadow-sm appearance-none focus:outline-none focus:shadow-outline" />

                            </div>
                            <div>
                                {
                                    loading ? <button type="submit" className="w-full px-3 py-4 text-white bg-[#f2a413] rounded-md focus:bg-[#f2a413] focus:outline-none">Please wait...</button>
                                        : <button type="submit" className="w-full px-3 py-4 text-white bg-[#f2a413] rounded-md focus:bg-[#f2a413] focus:outline-none">Login</button>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;