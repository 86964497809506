import React, { useState } from "react";
import axios from "axios";
import {
    GoogleMap,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import Duration from "./Duration";
import Swal from "sweetalert2";
import { useEffect } from "react";

const libraries = ["places", "directions"];
const mapContainerStyle = {
    width: "100%",
    height: "50vh",
};
const center = {
    lat: 31.582045,
    lng: 74.329376,
};
const options = {};

const MainMaps = ({ data, setAllPoints, start_date_time, name, vehicle_id, locations, url, isRouting, tourClick, setTourClick, calculateData }) => {


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyA3DG_FqRg0n5QekrWEIKE1KthsN841ZAY",
        libraries,
    });

    const [origin2, setOrigin2] = React.useState("");
    const [destination2, setDestination2] = React.useState("");
    const [waypoints2, setWaypoints2] = React.useState([]);
    const [response, setResponse] = React.useState(null);

    const [pointsName, setPointsName] = React.useState([]);
    const [calculuteMessage, setCalculuteMessage] = React.useState("");
    const [mailSending, setMailSending] = React.useState(false);
    const tourClick2 = React.useRef(true);

    React.useEffect(() => {

        if (data) {
            setOrigin2(data.origin.lat + "," + data.origin.lng);
            setDestination2(data.destination.lat + "," + data.destination.lng);
            const waypoints2 = data.waypoints.map((item) => {
                return {
                    location: item.lat + "," + item.lng,
                }
            })
            setWaypoints2(waypoints2);


            const totalPoints = waypoints2.length + 1;
            let pointsName2 = [];
            let fromPoint = data.origin
            let toPoint = waypoints2.length > 0 ? data.waypoints[0] : data.destination;
            for (let i = 0; i < totalPoints; i++) {
                let obj = {
                    from: fromPoint,
                    to: toPoint,
                    distance: '',
                    duration: '',
                    duration_value: ''
                }
                fromPoint = toPoint;
                toPoint = i + 1 < waypoints2.length ? data.waypoints[i + 1] : data.destination;


                pointsName2.push(obj);

            }
            console.log(pointsName2);
            let stops = []
            try {
                // stops.push({
                //     location_id: pointsName[0].from.id,
                //     eta: 0,
                // })
                // pointsName.forEach((item) => {
                //     //console.log(item);
                //     stops.push({
                //         location_id: item.to.id,
                //         eta: item.duration_value
                //     })
                // })
                // add 6 hours to start_date_time
                let start_date_time2 = new Date(start_date_time);
                start_date_time2.setHours(start_date_time2.getHours() + 6);

                // send the data to the backend
                setTimeout(() => {
                    if (tourClick2.current) {
                        tourClick2.current = false;

                        const data = JSON.stringify({
                            name,
                            vehicle_id,
                            start_date_time: start_date_time2,
                            pointsname: pointsName2,
                        })
                        console.log(data);


                        // ---------- SENDING DATA TO BACKEND ---------------

                        fetch(url, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                            body: JSON.stringify({
                                name,
                                vehicle_id,
                                start_date_time: start_date_time2,
                                pointsname: pointsName2
                            })
                        })
                            .then(res => res.json())
                            .then(data => {
                                console.log(data);
                                const eta_info = data.location_eta;
                                const close_info = data.close_status;
                                setEtaInfo(eta_info);
                                setCloseInfo(close_info);
                                let totalMsg = ''
                                data.close_status.forEach((item) => {
                                    const id = item.location_id;
                                    let name = '';
                                    if (locations.locations) {
                                        name = locations.locations.find((item) => item.id === id).name;
                                    }
                                    // get the name of the location

                                    let meg = `${name} ${item.message}`;
                                    totalMsg += meg + '\r\n';
                                })
                                setCalculuteMessage(totalMsg);

                                data.location_eta.forEach((item) => {
                                    // if first item, continue
                                    if (item.location_id === pointsName[0].from.id) {
                                        return;
                                    }
                                    // find the location
                                    const id = item.location_id;
                                    const duration = secondsToHms(item.eta);
                                    // set the duration to pointsName
                                    const index = pointsName.findIndex((item) => item.to.id === id);
                                    pointsName[index].duration = duration;
                                })

                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }
                }, 2000);



            } catch (error) {
                console.log(error);
            }
        }
    }, [data]);

    const directionsCallback = (response) => {
        //console.log(response);

        if (response !== null) {
            if (response.status === "OK") {
                setResponse(response);
            } else {
                console.log("response: ", response);
            }
        }
    };

    const secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    const getDistance = (origin, destination) => {
        let distance = '';
        let duration = '';
        let duration_value = '';
        const config = {
            method: "get",
            url: `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=AIzaSyA3DG_FqRg0n5QekrWEIKE1KthsN841ZAY`,
            headers: {},
        };

        axios(config)
            .then(function (response) {
                //console.log(JSON.stringify(response.data));
                distance = response.data.routes[0].legs[0].distance.text;
                duration = response.data.routes[0].legs[0].duration.text;
                duration_value = response.data.routes[0].legs[0].duration.value;
                console.log(distance, duration, duration_value);
            })
            .catch(function (error) {
                console.log(error);
            })
        return { distance, duration, duration_value };
    };

    /*React.useEffect(() => {
        const totalPoints = waypoints2.length + 1;
        let pointsName = [];
        let fromPoint = data.origin
        let toPoint = waypoints2.length > 0 ? data.waypoints[0] : data.destination;
        for (let i = 0; i < totalPoints; i++) {
            let obj = {
                from: fromPoint,
                to: toPoint,
                distance: '',
                duration: '',
                duration_value: ''
            }
            fromPoint = toPoint;
            toPoint = i + 1 < waypoints2.length ? data.waypoints[i + 1] : data.destination;


            pointsName.push(obj);

        }
        console.log(pointsName);
        setPointsName(pointsName);
        setAllPoints(pointsName);

    }, [data, waypoints2]);*/


    // ETA info state
    const [etaInfo, setEtaInfo] = useState([]);

    // Close info state
    const [closeInfo, setCloseInfo] = useState([]);

    const token = localStorage.getItem('token');


    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);
    if (loadError) return "Error loading maps";
    if (!isLoaded) return "loading maps";

    const DirectionsServiceOption = {
        destination: destination2,
        origin: origin2,
        // via cox's bazar
        waypoints: waypoints2,
        travelMode: "DRIVING",
    };











    // const sendEmail = () => {
    //     setMailSending(true);
    //     const token = localStorage.getItem('token');
    //     console.log(start_date_time); // Wed Sep 28 2022 15:20:57 GMT+0600 (Bangladesh Standard Time)
    //     // convert the date to the format 2022-02-15 02:00:00
    //     let start_date_time2 = start_date_time
    //     // add 6 hours
    //     start_date_time2.setHours(start_date_time2.getHours() + 6);
    //     start_date_time2 = start_date_time.toISOString().slice(0, 19).replace('T', ' ');
    //     console.log(start_date_time2);
    //     let stops = []
    //     stops.push({
    //         location_id: pointsName[0].from.id,
    //         eta: 0
    //     })
    //     pointsName.forEach((item) => {
    //         //console.log(item);
    //         stops.push({
    //             location_id: item.to.id,
    //             eta: item.duration_value
    //         })
    //     })
    //     console.log(stops);

    //     const sending = JSON.stringify({
    //         start_date_time: start_date_time2,
    //         pointsname: pointsName,
    //         name: name,
    //         vehicle_id: vehicle_id,
    //     });
    //     console.log(sending);

    //     fetch(url, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${token}`
    //         },
    //         body: JSON.stringify({
    //             start_date_time: start_date_time2,
    //             pointsname: pointsName,
    //             name: name,
    //             vehicle_id: vehicle_id,
    //         })
    //     })
    //         .then(res => res.json())
    //         .then(data => {
    //             console.log("Create/Update response", data);
    //             if (data.status === 200) {
    //                 setMailSending(false);
    //                 Swal.fire({
    //                     title: 'Success!',
    //                     text: data.message,
    //                     icon: 'success',
    //                     confirmButtonText: 'Ok'
    //                 })
    //             }
    //             else {
    //                 setMailSending(false);
    //                 Swal.fire({
    //                     title: 'Error!',
    //                     text: data.message,
    //                     icon: 'error',
    //                     confirmButtonText: 'Ok'
    //                 })
    //             }

    //         })

    // }


    return (
        <div>
            <div className="my-5 text-left">

                <div>

                    <div className="grid grid-flow-row gap-5">
                        {
                            etaInfo.map(
                                (item2, index2) => {
                                    return (

                                        <div key={index2} className="p-7 bg-gray-100 rounded-md shadow-md">

                                            <h5 className="">Location Name: <span className="font-bold">
                                                {item2.location_name}
                                            </span></h5>
                                            <h6 className="">Arrival Time: <span className="font-bold">
                                                {item2.arrival_time}
                                            </span></h6>
                                            <h5 className="card-text">ETA: <span className="font-bold">
                                                {
                                                    item2.eta === 0 ? "0 Minutes" : secondsToHms(item2.eta)
                                                }
                                            </span></h5>
                                            <h5>
                                                Stop Time: <span className="font-bold">
                                                    {item2.stop_time} Minutes
                                                </span>
                                            </h5>
                                            <h5>
                                                Departure Time: <span className="font-bold">
                                                    {item2.leaving_time}
                                                </span>
                                            </h5>
                                            <h5>
                                                {/* check if colseInfo has a message or not */}
                                                {
                                                    closeInfo.find((item3) => item3.location_id === item2.location_id) ?
                                                        <span>
                                                            Status: <span className="text-yellow-500 font-bold">
                                                                {
                                                                    closeInfo.find((item) => item.location_id === item2.location_id).message === "The place is closed" ? (
                                                                        <span className="text-red-500 font-bold">
                                                                            {closeInfo.find((item) => item.location_id === item2.location_id).message}
                                                                        </span>
                                                                    ) : (
                                                                        <span className="text-yellow-500 font-bold">
                                                                            {closeInfo.find((item) => item.location_id === item2.location_id).message}
                                                                        </span>

                                                                    )
                                                                }
                                                            </span>

                                                        </span>
                                                        : <span>
                                                            Status: <span className="text-green-500 font-bold">
                                                                The place is open
                                                            </span>
                                                        </span>
                                                }

                                            </h5>


                                        </div>

                                    )
                                }

                            )
                        }
                    </div>
                </div>
            </div>

            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={8}
                center={center}
                onLoad={onMapLoad}
            >
                {response !== null && (
                    <DirectionsRenderer
                        options={{
                            directions: response,
                        }}
                    />
                )}

                <DirectionsService
                    options={DirectionsServiceOption}
                    callback={directionsCallback}
                />
            </GoogleMap>



            {/* <div className='flex justify-center'>
                {mailSending ? (
                    <button className='bg-[#f2a413] rounded text-white px-7 py-2 my-10'>Loading...</button>
                ) : (
                    <button onClick={sendEmail} className='bg-[#f2a413] rounded text-white px-7 py-2 my-10'>Send Email</button>
                )}
            </div> */}
        </div>
    );
};

export default MainMaps;
