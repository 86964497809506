import React from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const AddVehicle = () => {

    // get the token from local storage
    const token = localStorage.getItem('token');

    const baseURL = 'https://api.lekkerwinetours.com'

    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
    const [vhicleList, setVehicleList] = React.useState([]);
    const [vehicleId, setVehicleId] = React.useState(null);

    const onSubmit = (data) => {
        let url = `${baseURL}/api/create/vahicle`
        if (vehicleId) {
            url = `${baseURL}/api/update/vahicle`
        }
        // send data to the backend
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })

            .then(res => res.json())

            .then(data => {
                if (data.status === 200) {
                    console.log(data);
                    Swal.fire({
                        title: 'Success!',
                        text: 'Vehicle added successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    window.location.reload();
                }
            })

            // if no response from the backend
            .catch(err => {
                console.log(err);
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1500
                })
            })

        // reset the form
        reset();

    };

    // get the vehicle list
    React.useEffect(() => {
        fetch(`${baseURL}/api/vahicle/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setVehicleList(data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [token, baseURL])


    const removeVhicle = (id) => {
        const confirm = window.confirm('Are you sure you want to delete this vehicle?');
        if (!confirm) {
            return;
        }
        fetch(`${baseURL}/api/delete/vahicle/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                if (data.status === 200) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Vehicle deleted successfully',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    setVehicleList(vhicleList.filter(item => item.id !== id));
                }
            })
            .catch(err => {
                console.log(err);
            }
            )
    }


    const editVhicle = (id) => {
        const vehicle = vhicleList.find(item => item.id === id);
        setVehicleId(id);
        setValue('make', vehicle.make);
        setValue('model', vehicle.model);
        setValue('max_passenger', vehicle.max_passenger);
        setValue('id', vehicle.id);
    }

    const navigate = useNavigate();
    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);

    return (
        <div className='w-5/6 mx-auto'>
            <div className='lg:w-2/3 mx-auto'>
                <h1 className='py-10 text-2xl font-bold'>Add Vehicle</h1>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='flex flex-col mb-20'>

                        <div className='flex flex-col'>
                            <label className='py-2' htmlFor="make">Make:</label>
                            <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="make" {...register('make', { required: true })} />
                            {errors.make && errors.make.type === "required" && <span className="text-red-600 italic"><small>Type a name</small></span>}
                        </div>

                        <div className='flex flex-col'>
                            <label className='py-2' htmlFor="model">Model:</label>
                            <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="model" {...register('model', { required: true })} />
                            {errors.model && errors.model.type === "required" && <span className="text-red-600 italic"><small>Type a model</small></span>}
                        </div>

                        <div className='flex flex-col'>
                            <label className='py-2' htmlFor="max_passenger">Max passenger:</label>
                            <input type="number" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="max_passenger" {...register('max_passenger', { required: true })} />

                            {errors.max_passenger && errors.max_passenger.type === "required" && <span className="text-red-600 italic"><small>Select max passenger</small></span>}
                        </div>


                        <input className='bg-[#f2a413] text-white px-7 cursor-pointer py-3 rounded mt-4 hover:bg-[#f2a413] transition duration-200' type="submit" value="Add vehicle" />

                    </div>
                </form>

                <div className='my-5'>
                    <h1 className='text-3xl font-bold mb-4'>All Vehicles</h1>
                    <div>
                        {vhicleList && vhicleList.map((vehicle, index) => {
                            return (
                                <div key={index} className='my-2 py-4 px-7 bg-gray-100 rounded'>
                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <h1 className='text-xl font-bold'>Model: {vehicle.model}</h1>
                                            <div className='flex'>
                                                <p className='mr-5'>Make: {vehicle.make}</p>
                                                <p>Max passenger: {vehicle.max_passenger}</p>
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <button onClick={() => editVhicle(vehicle.id)} className='bg-blue-500 text-white px-5 py-2 rounded mr-3 hover:bg-[#f2a413] transition duration-200'>Edit</button>
                                            <button onClick={() => removeVhicle(vehicle.id)} className='bg-red-500 text-white px-5 py-2 rounded hover:bg-[#f2a413] transition duration-200'>Delete</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>


        </div>
    );
};

export default AddVehicle;