import React from "react";

export default function StopRoute({ locations, stop }) {

    const [updating, setUpdating] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);

    const changeLocation = (e) => {
        const token = localStorage.getItem('token');
        const url = 'https://api.lekkerwinetours.com/api/update/stop/location'
        const data = {
            tour_id: stop.tour_id,
            new_location_id: e.target.value,
            old_location_id: stop.location_id
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setUpdating(false);
                stop.location_id = e.target.value;
                // let new_location_name = locations.find((location) => location.id === e.target.value).name;
                // stop.name = new_location_name;
                alert('Location updated');
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            })
    }

    const confirmLocation = () => {
        const url = 'https://api.lekkerwinetours.com/api/update/location/confirmed';
        const token = localStorage.getItem('token');
        const data = {
            "tour_id": stop.tour_id,
            "location_id": stop.location_id,
            "confirmed": "Y"
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                setConfirmed(true);
                alert('Location confirmed');
            })
            .catch(err => {
                console.log(err);
            })
    }

    React.useEffect(() => {
        if (stop.confirmed === "Y") {
            setConfirmed(true);
        }
    }, [])





    return (
        <div className="py-4 px-6 bg-gray-200 rounded mt-4">
            <div className="flex justify-between">
                {updating ? (
                    <select value={stop.location_id} style={{ minWidth: "200px" }}
                        onChange={changeLocation} className="py-2 px-3 outline-none bg-gray-100 mb-3 rounded-md">
                        {locations.map((location) => (
                            <option value={location.id}>{location.name}</option>
                        ))}
                    </select>
                ) : (
                    <div>
                        <div className="font-bold text-lg">{stop.name}</div>
                        <div className="text-sm">Arrival time: {stop.arrival_time}</div>
                        <h3>
                            ETA: {
                                // convert seconds to hours and minutes
                                Math.floor(stop.eta / 3600) + "h " + Math.floor((stop.eta % 3600) / 60) + "m"
                            }
                        </h3>
                        <h3>
                            Avg Stop Time: {

                                // convert minutes to hours and minutes
                                Math.floor(stop.stop_time / 60) + "h " + stop.stop_time % 60 + "m"


                            }
                        </h3>
                        <h3>
                            Cell Number: {
                                // check if cell number is multiple or not and display accordingly
                                stop.number.includes(',') ? (
                                    stop.cell_number.split(',').map((number) => (
                                        <span>{number}</span>
                                    ))
                                ) : (
                                    <span>{stop.number}</span>
                                )
                            }
                        </h3>
                        <h3>
                            Notes: {
                                stop.notes === null ? (
                                    <span>None</span>
                                ) : (
                                    <span>{stop.notes}</span>
                                )
                            }
                        </h3>


                    </div>
                )}
                {confirmed ? (
                    <button className="bg-gray-500 text-white rounded-md px-3" disabled>Confirmed</button>
                ) : (
                    <div>
                        <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={confirmLocation}>
                            Confirm
                        </button>
                        {/* <button onClick={e => setUpdating(!updating)} className="bg-yellow-500 text-white px-4 py-2 rounded mx-2">
                            Update
                        </button> */}
                    </div>
                )}
            </div>
        </div>
    )

}
