import React from "react";
import { Link } from "react-router-dom";

const token = localStorage.getItem("token");

export default function AllLocations() {
    // STATES
    const [locations, setLocations] = React.useState([]);

    // HOOKS
    React.useEffect(() => {
        const url = "https://api.lekkerwinetours.com/api/location/list";
        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setLocations(data);
            });
    }, []);


    const deleteLocation = (id) => {
        const confirm = window.confirm("Are you sure you want to delete this location?");
        if (confirm) {
            const url = `https://api.lekkerwinetours.com/api/location/delete/${id}`;
        fetch(url, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    alert("Location deleted successfully");
                    setLocations(locations.filter((location) => location.id !== id));
                }
            });
        }
    };


    return (
        <div className="w-5/6 mx-auto">
            <div className="lg:w-2/3 mx-auto my-8">
                <h1 className="text-3xl font-bold">All Locations</h1>
                <div className="py-10">
                    {locations.map((location) => (
                        <div className="py-4 px-6 bg-gray-200 rounded flex justify-between my-3">
                            <div>
                                <h2 className="text-lg font-bold">Name: {location.name}</h2>
                                <p>Location type: {location.loc_type}</p>
                            </div>
                            <div>
                                <Link to={`/edit-location/${location.id}`}><button className="bg-blue-500 text-white px-4 mx-1 font-bold py-2 rounded">Edit</button></Link>
                                <button onClick={()=>deleteLocation(location.id)} className="bg-red-500 text-white px-4 mx-1 font-bold py-2 rounded">Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
