import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
const Navbar = () => {

    const navigate = useNavigate();


    // get the user from local storage
    const user = JSON.parse(localStorage.getItem('user'));


    // logout function
    const handleLogout = () => {
        // remove the token from local storage
        const url = 'https://api.lekkerwinetours.com/api/logout';
        const token = localStorage.getItem('token');
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                localStorage.removeItem('token');
                // remove the user from local storage
                localStorage.removeItem('user');
                // redirect to login page
                navigate('/');
                console.log("logout clicked");
                Swal.fire({
                    title: 'Logged Out!',
                    text: 'You have been logged out successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            })
            .catch(err => {
                console.log(err);
            }
            )


    };

    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);



    return (
        <div className='h-[60px] bg-[#f2a413] text-white'>
            <div className='w-5/6 mx-auto flex justify-between items-center'>
                <div>
                    <NavLink to="/home">
                        <img src="/lekker.jpeg" alt="logo" className='h-[50px] mt-1' />
                    </NavLink>
                </div>
                {
                    user ? (
                        <div className='pt-1 hidden lg:block'>
                            <NavLink to="/tour-list">
                                <button className='bg-[#f2a413] text-white px-4 py-2 rounded mx-2 hover:bg-[#f2a413] transition duration-200 '>All tours</button>
                            </NavLink>

                            <NavLink to="/add-location">
                                <button className='bg-[#f2a413] text-white px-4 py-3 rounded mx-2 hover:bg-[#f2a413] transition duration-200 '>Add Location</button>
                            </NavLink>

                            <NavLink to="/add-vehicle">
                                <button className='bg-[#f2a413] text-white px-4 py-2 rounded mx-2 hover:bg-[#f2a413] transition duration-200'>Add Vehicle</button>
                            </NavLink>

                            <NavLink to="/plan-a-tour">
                                <button className='bg-[#f2a413] text-white px-4 py-2 rounded mx-2 hover:bg-[#f2a413] transition duration-200'>Plan a tour</button>
                            </NavLink>

                            <button onClick={handleLogout} className='bg-white text-gray-700 px-4 py-2 rounded mx-2  transition duration-200'>Logout</button>

                        </div>
                    ) : (
                        <div className='pt-1 hidden lg:block'>

                            <button className='bg-[#f2a413] text-white px-4 py-3 rounded mx-2 hover:bg-[#f2a413] transition duration-200 '></button>

                        </div>
                    )
                }
            </div>


        </div>
    );
};

export default Navbar;