import React from "react";
import axios from "axios";
import MiniMap from "./Map";

export default function Test() {
    const [distance, setDistance] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [origin, setOrigin] = React.useState('');
    const [destination, setDestination] = React.useState('');

    const getDistance = async (e) => {
        e.preventDefault();
        const config = {
            method: "get",
            url: `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=AIzaSyA3DG_FqRg0n5QekrWEIKE1KthsN841ZAY`,// AIzaSyA3DG_FqRg0n5QekrWEIKE1KthsN841ZAY 
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
        };

        await axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setDistance(response.data.routes[0].legs[0].distance.text);
                setDuration(response.data.routes[0].legs[0].duration.text);
            })
            .catch(function (error) {
                console.log(error);
            });
    };


    return <div>
        <br />
        <div>
            <label>From:</label>
            <input type="text" name="from" className="border border-black"
                onChange={(e) => setOrigin(e.target.value)}
                value={origin}
            />
            <label>To:</label>
            <input type="text" name="to" className="border border-black"
                onChange={(e) => setDestination(e.target.value)}
                value={destination}
            />

            <button onClick={getDistance} className="button bg-blue-500 px-2 mx-2">Submit</button>
        </div>
        <hr />
        <div>
            <h5>Total Distance: {distance}</h5>
            <h5>Total Duration: {duration}</h5>
        </div>
        {/* <MiniMap /> */}
        <br /><br />

    </div>;
}
