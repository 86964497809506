import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const token = localStorage.getItem('token');

export default function AddLocationType() {
    
    const [locationTypeList, setLocationTypeList] = useState([]);
    const [locationType, setLocationType] = useState("");


    useEffect(() => {
        const url = 'https://api.lekkerwinetours.com/api/locationtype/list';
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLocationTypeList(data)
                console.log(data)
            })
    }, [])


    // ADD LOCATION TYPE
    const addLocationType = (e) => {
        e.preventDefault();
        const url = 'https://api.lekkerwinetours.com/api/create/location/type';
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: locationType
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                alert(data.message)
                if (data.status === 200) {
                    window.location.reload();
                }
            })
    }


    // DELETE LOCATION TYPE
    const deleteLocationType = (id) => {
        const confirm = window.confirm('Are you sure you want to delete this location type?');
        if (confirm) {
            const url = `https://api.lekkerwinetours.com/api/locationtype/delete/${id}`;
            fetch(url, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data)
                    alert(data.message)
                    if (data.status === 200) {
                        window.location.reload();
                    }
                })
        }
    }

    const navigate = useNavigate();
    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);


    return (
        <div className="w-5/6 mx-auto">
            <div className="lg:w-2/3 mx-auto">
                <div className="mt-10">
                    <h1 className="text-2xl font-bold text-gray-900">Add Location Type</h1>
                </div>

                <div>
                    <label className="block mt-5">
                        <span className="text-gray-700">Location Type</span>
                    </label>
                    <div className="flex">
                        <input type="text" className="border px-3 form-input mt-1 block w-full" placeholder="Location Type Name" value={locationType} onChange={(e) => setLocationType(e.target.value)} />
                        <button onClick={addLocationType} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2">Add</button>
                    </div>
                </div>


                <div className="mt-10">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Location Type List</h1>
                    </div>
                    <div className="mt-5">
                        {locationTypeList.map((locationType) => (
                            <div key={locationType.id} className="my-2 px-5 py-2 bg-gray-100 rounded">
                                <div className="flex justify-between">
                                    <div className="text-lg font-bold">Name: {locationType.name}</div>
                                    <div>
                                        <button onClick={()=>deleteLocationType(locationType.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2">Delete</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
