import React, { useState } from 'react';
import { useParams } from 'react-router';
import StopRoute from '../components/shared/StopRoute';
import { useNavigate } from 'react-router-dom';
import {
    GoogleMap,
    useLoadScript,
    DirectionsService,
    DirectionsRenderer,
} from "@react-google-maps/api";
import { data } from 'autoprefixer';
import Swal from 'sweetalert2';

const libraries = ["places", "directions"];
const mapContainerStyle = {
    width: "100%",
    height: "50vh",
};
const center = {
    lat: 31.582045,
    lng: 74.329376,
};

const SingleTour = () => {

    let { tourId } = useParams();



    // get the token from local storage
    const token = localStorage.getItem('token');
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyA3DG_FqRg0n5QekrWEIKE1KthsN841ZAY",
        libraries,
    });

    const [origin2, setOrigin2] = React.useState("");
    const [destination2, setDestination2] = React.useState("");
    const [waypoints2, setWaypoints2] = React.useState([]);
    const [response, setResponse] = React.useState(null);
    const [showMap, setShowMap] = React.useState(false);



    const directionsCallback = (response) => {
        //console.log(response);

        if (response !== null) {
            if (response.status === "OK") {
                setResponse(response);
            } else {
                console.log("response: ", response);
            }
        }
    };




    const [singleTour, setSingleTour] = React.useState();
    const [eventbriteURL, setEventbriteURL] = React.useState();


    React.useEffect(() => {
        fetch(`https://api.lekkerwinetours.com/api/tour/info/${tourId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => res.json())

            .then(data => {
                setSingleTour(data);
                console.log(data);
                if (data.stop_info) {
                    // setOrigin2(data.origin.lat + "," + data.origin.lng);
                    // setDestination2(data.destination.lat + "," + data.destination.lng);
                    // const waypoints2 = data.waypoints.map((item) => {
                    //     return {
                    //         location: item.lat + "," + item.lng,
                    //     }
                    // });
                    // setWaypoints2(waypoints2);

                    // setOrigin2(data.stop_info[0].stop_name);
                    // setDestination2(data.stop_info[data.stop_info.length - 1].stop_name);
                    // setWaypoints2(data.stop_info.slice(1, data.stop_info.length - 1));

                    const origin2address = data.stop_info[0].address //"https://www.google.com/maps/place/115+N+Center+St,+Longview,+TX+75601/@32.4943855,-94.7412598,19.61z/data=!4m5!3m4!1s0x863638884a2298b5:0xcbfb089190ed4c03!8m2!3d32.4948126!4d-94.7404441"
                    const origin2lat = origin2address.split("@")[1].split(",")[0];
                    const origin2lng = origin2address.split("@")[1].split(",")[1];
                    setOrigin2(origin2lat + "," + origin2lng);

                    const destination2address = data.stop_info[data.stop_info.length - 1].address //"https://www.google.com/maps/place/115+N+Center+St,+Longview,+
                    const destination2lat = destination2address.split("@")[1].split(",")[0];
                    const destination2lng = destination2address.split("@")[1].split(",")[1];
                    setDestination2(destination2lat + "," + destination2lng);

                    const waypoints2 = data.stop_info.slice(1, data.stop_info.length - 1).map((item) => {
                        const waypoint2address = item.address;
                        const waypoint2lat = waypoint2address.split("@")[1].split(",")[0];
                        const waypoint2lng = waypoint2address.split("@")[1].split(",")[1];
                        return {
                            location: waypoint2lat + "," + waypoint2lng,
                        }
                    });
                    setWaypoints2(waypoints2);
                    setShowMap(true);
                }
            })
    }, []);

    const confirmTour = () => {
        const url = 'https://api.lekkerwinetours.com/api/tour/confirm';
        const token = localStorage.getItem('token');
        const data = {
            "tour_id": tourId,
            "eventbrite_url": eventbriteURL
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                alert('Tour confirmed');
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
            }
            )

    }

    const navigate = useNavigate();
    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);

    const mapRef = React.useRef();
    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
    }, []);
    if (loadError) return "Error loading maps";
    if (!isLoaded) return "loading maps";

    const DirectionsServiceOption = {
        destination: destination2,
        origin: origin2,
        // via cox's bazar
        waypoints: waypoints2,
        travelMode: "DRIVING",
    };


    // Send email function
    const sendEmail = () => {

        console.log("SEND EMAIL IS CALLED")

        fetch(`https://api.lekkerwinetours.com/api/send/tentative/email/${tourId} `,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => res.json())
            .then(data => {

                console.log(data);
                if (data.status === 200) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: data.message,

                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Sorry, Email sending failed!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })



    }


    return (
        <div>
            <div className='w-5/6 mx-auto'>
                <div>
                    <h1 className='py-7 text-2xl font-bold'>
                        {singleTour && singleTour.tour_info[0].name}
                    </h1>

                    <div>
                        <div>
                            <div className='flex justify-between bg-gray-100 p-7 rounded-lg my-5'>
                                <div className='flex flex-col lg:flex-row justify-between w-full'>
                                    <div className='flex flex-col'>

                                        <p className='text-sm'>
                                            <span className='font-bold'>Status:</span> {
                                                singleTour?.tour_info[0]?.status === 'Confirmed' ?
                                                    <span className='bg-green-500 px-3 text-white rounded-full'>{singleTour?.tour_info[0]?.status}</span>
                                                    :
                                                    <span className='bg-yellow-500 px-3 text-white rounded-full'>{singleTour?.tour_info[0]?.status}</span>
                                            }
                                        </p>
                                        <p className='text-sm'>
                                            <span className='font-bold'>Vehicle: </span> {singleTour?.tour_info[0]?.model}
                                        </p>
                                        <p className='text-sm'>
                                            <span className='font-bold'>Eventbrite URL: </span>
                                            <a href={singleTour?.tour_info[0]?.eventbrite_url} target='_blank' rel='noreferrer'>{singleTour?.tour_info[0]?.eventbrite_url}</a>
                                        </p>
                                        <p className='text-sm'>
                                            <span className='font-bold'>Start Date: </span>
                                            {/* split date & convert to month name */}
                                            {singleTour && new Date(singleTour.tour_info[0].start_date_time).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                                        </p>
                                        <p className='text-sm'>
                                            <span className='font-bold'>Start Time: </span>
                                            {/* split time with am pm */}
                                            {singleTour && new Date(singleTour.tour_info[0].start_date_time).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                                        </p>


                                        {
                                            singleTour?.tour_info[0]?.status !== 'Confirmed' ? (
                                                <div className='mt-10'>


                                                    <button onClick={sendEmail} className="bg-green-500 text-white px-4 py-2 rounded">
                                                        Send Tentative Email
                                                    </button>


                                                </div>
                                            ) : (
                                                null
                                            )

                                        }

                                    </div>

                                    <div className='py-10 lg:py-0'>
                                        {showMap &&
                                            <div style={{ minWidth: '300px' }}>
                                                <GoogleMap
                                                    mapContainerStyle={mapContainerStyle}
                                                    zoom={8}
                                                    center={center}
                                                    onLoad={onMapLoad}
                                                >
                                                    {response !== null && (
                                                        <DirectionsRenderer
                                                            options={{
                                                                directions: response,
                                                            }}
                                                        />
                                                    )}

                                                    <DirectionsService
                                                        options={DirectionsServiceOption}
                                                        callback={directionsCallback}
                                                    />
                                                </GoogleMap>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 className='text-xl font-bold'>Routes</h3>
                                <hr />
                                {singleTour?.stop_info.map((stop, index) => (
                                    <StopRoute
                                        key={index}
                                        locations={singleTour?.locations}
                                        stop={stop}
                                    />
                                ))}
                            </div>

                            <div className=' my-10'>
                                {singleTour?.tour_info[0]?.status !== 'Confirmed' ? (
                                    <div>
                                        <input type="text" placeholder='Enter Eventbrite URL' className='w-1/2 p-2 border border-gray-300 rounded-lg' onChange={(e) => setEventbriteURL(e.target.value)} />
                                        <button onClick={confirmTour} className='bg-green-500 text-white px-3 py-2 rounded-lg ml-3'>Confirm Tour</button>

                                    </div>
                                ) : null}
                            </div>
                            <br /><br /><br />

                        </div>

                    </div>

                </div>

            </div>
        </div>
    );
};

export default SingleTour;