import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const TourList = () => {



    // get the token from local storage
    const token = localStorage.getItem('token');



    const navigate = useNavigate();
    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);

    // state for all tour
    const [tour, setTour] = useState([]);

    //  fetch all tour
    React.useEffect(() => {
        fetch('https://api.lekkerwinetours.com/api/tour/list', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

            .then(res => res.json())
            .then(data => {
                console.log(data)
                setTour(data);
            })
    }, []);




    // delete tour function
    const deleteTour = (id) => {
        // confirmation
        const confirmation = window.confirm('Are you sure you want to delete this tour?');
        if (confirmation) {
            fetch(`https://api.lekkerwinetours.com/api/tour/delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    "tour_id": id
                })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    Swal.fire({
                        icon: 'success',
                        title: 'Tour deleted',
                        showConfirmButton: false,
                        timer: 1500
                    })

                    // filter and remove the deleted tour
                    const newTour = tour.filter((item) => item.id !== id);
                    setTour(newTour);

                })


        }
    }


    return (
        <div>
            <div className='w-5/6 mx-auto'>
                <div>
                    <h1 className='py-7 text-2xl font-bold'>All Tours</h1>

                    <div>
                        <div>
                            {
                                tour.map((tour, index) => (
                                    <div key={index} className='flex justify-between bg-gray-100 p-7 rounded-lg my-5'>
                                        <div className='flex flex-row justify-between'>
                                            <div className='flex flex-col'>
                                                <h1 className='text-2xl font-bold'>{tour.name}</h1>
                                                <p className='text-sm'>

                                                    {/* split date & convert to month name */}
                                                    {new Date(tour.start_date_time).toDateString().split(' ').slice(1, 4).join(' ')}
                                                    <br />
                                                    {/* split time with am pm */}
                                                    {new Date(tour.start_date_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className='flex flex-col'>
                                                <p className='text-sm'>
                                                    Status: {
                                                        tour.status === 'Confirmed' ?
                                                            <span className='bg-green-500 px-3 text-white rounded-full'>{tour.status}</span>
                                                            :
                                                            <span className='bg-yellow-500 px-3 text-white rounded-full'>{tour.status}</span>
                                                    }
                                                </p>
                                            </div>
                                            <div className='grid grid-cols-1 lg:grid-cols-3 gap-3 mt-3 lg:mt-0'>
                                                <NavLink to={`/tour-list/${tour.id}`}>
                                                    <button className='w-full px-5 py-2 text-yellow-600 bg-yellow-200 hover:bg-[#f2a413] transition duration-200 hover:text-white rounded'>View</button>
                                                </NavLink>
                                                <NavLink to={`/tour-list/edit/${tour.id}`}>
                                                    <button className='w-full px-5 py-2 bg-sky-200 text-sky-600 hover:bg-sky-600 transition duration-200 hover:text-white rounded'>Edit</button>
                                                </NavLink>

                                                <button onClick={() => deleteTour(tour.id)} className='w-full px-5 py-2 bg-red-200 text-red-600 hover:bg-red-600 transition duration-200 hover:text-white rounded'>Delete</button>

                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TourList;