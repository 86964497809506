import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const Home = () => {

    const navigate = useNavigate();


    // get the user from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user);

    if (user === null) {
        navigate('/');
    }



    // logout function
    const handleLogout = () => {
        // remove the token from local storage
        const url = 'https://api.lekkerwinetours.com/api/logout';
        const token = localStorage.getItem('token');
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                localStorage.removeItem('token');
                // remove the user from local storage
                localStorage.removeItem('user');
                // redirect to login page
                navigate('/');
                console.log("logout clicked");
                Swal.fire({
                    title: 'Logged Out!',
                    text: 'You have been logged out successfully!',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
            })
            .catch(err => {
                console.log(err);
            }
            )


    };

    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);
    
    return (
        <div>
            <div className='flex flex-col lg:flex-row justify-center items-center mt-20'>
                <NavLink to="/tour-list">
                    <button className='hover:bg-[#f2a413] mb-2 text-white px-7 py-3 rounded mx-3 bg-[#f2a413] transition duration-200'>All tour</button>
                </NavLink>

                <NavLink to="/add-location">
                    <button className='hover:bg-[#f2a413] mb-2 text-white px-7 py-3 rounded mx-3 bg-[#f2a413] transition duration-200'>Add Location</button>
                </NavLink>

                <NavLink to="/add-vehicle">
                    <button className='hover:bg-[#f2a413] mb-2 text-white px-7 py-3 rounded mx-3 bg-[#f2a413] transition duration-200'>Add Vehicle</button>
                </NavLink>

                <NavLink to="/plan-a-tour">
                    <button className='hover:bg-[#f2a413] mb-2 text-white px-7 py-3 rounded mx-3 bg-[#f2a413] transition duration-200'>Plan a tour</button>
                </NavLink>


                <div className='block lg:hidden'>
                    <button onClick={handleLogout} className='hover:bg-red-700 mb-2 text-white px-7 py-3 rounded mx-3 bg-red-500 transition duration-200'>Logout</button>
                </div>
            </div>
        </div>
    );
};

export default Home;