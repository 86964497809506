import React from 'react';

const Duration = ({item}) => {

    return (
        <div className="mb-3">
            <p>{item.from.name} to {item.to.name}</p>
            <div className="mx-3">
                {/* <p>Distance: {item.distance}</p> */}
                <p>Duration: {item.duration}</p>
            </div>
        </div>
    );
};

export default Duration;