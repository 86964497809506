import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link, useNavigate } from 'react-router-dom';


// import { MultiSelect } from "react-multi-select-component";

const baseURL = 'https://api.lekkerwinetours.com'

// get the token from local storage
const token = localStorage.getItem('token');




const AddLocation = () => {
    const timeLoop = [
        { label: '12:00 AM', value: '00:00:01' },
        { label: '12:30 AM', value: '00:30:00' },
        { label: '01:00 AM', value: '01:00:00' },
        { label: '01:30 AM', value: '01:30:00' },
        { label: '02:00 AM', value: '02:00:00' },
        { label: '02:30 AM', value: '02:30:00' },
        { label: '03:00 AM', value: '03:00:00' },
        { label: '03:30 AM', value: '03:30:00' },
        { label: '04:00 AM', value: '04:00:00' },
        { label: '04:30 AM', value: '04:30:00' },
        { label: '05:00 AM', value: '05:00:00' },
        { label: '05:30 AM', value: '05:30:00' },
        { label: '06:00 AM', value: '06:00:00' },
        { label: '06:30 AM', value: '06:30:00' },
        { label: '07:00 AM', value: '07:00:00' },
        { label: '07:30 AM', value: '07:30:00' },
        { label: '08:00 AM', value: '08:00:00' },
        { label: '08:30 AM', value: '08:30:00' },
        { label: '09:00 AM', value: '09:00:00' },
        { label: '09:30 AM', value: '09:30:00' },
        { label: '10:00 AM', value: '10:00:00' },
        { label: '10:30 AM', value: '10:30:00' },
        { label: '11:00 AM', value: '11:00:00' },
        { label: '11:30 AM', value: '11:30:00' },
        { label: '12:00 PM', value: '12:00:00' },
        { label: '12:30 PM', value: '12:30:00' },
        { label: '01:00 PM', value: '13:00:00' },
        { label: '01:30 PM', value: '13:30:00' },
        { label: '02:00 PM', value: '14:00:00' },
        { label: '02:30 PM', value: '14:30:00' },
        { label: '03:00 PM', value: '15:00:00' },
        { label: '03:30 PM', value: '15:30:00' },
        { label: '04:00 PM', value: '16:00:00' },
        { label: '04:30 PM', value: '16:30:00' },
        { label: '05:00 PM', value: '17:00:00' },
        { label: '05:30 PM', value: '17:30:00' },
        { label: '06:00 PM', value: '18:00:00' },
        { label: '06:30 PM', value: '18:30:00' },
        { label: '07:00 PM', value: '19:00:00' },
        { label: '07:30 PM', value: '19:30:00' },
        { label: '08:00 PM', value: '20:00:00' },
        { label: '08:30 PM', value: '20:30:00' },
        { label: '09:00 PM', value: '21:00:00' },
        { label: '09:30 PM', value: '21:30:00' },
        { label: '10:00 PM', value: '22:00:00' },
        { label: '10:30 PM', value: '22:30:00' },
        { label: '11:00 PM', value: '23:00:00' },
        { label: '11:30 PM', value: '23:30:00' },
    ]

    const [weekend, setWeekend] = useState([]);

    const [emails, setEmails] = useState([]);
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [numbers, setNumbers] = useState([]);
    const [locationTypeList, setLocationTypeList] = useState([]);

    const { register, formState: { errors }, handleSubmit, reset } = useForm();


    // -------------------- FORM SUBMISSION ---------------------


    const checkKeyDown = (e) => {
        if (e.code === 'Enter') e.preventDefault();
    };

    const onSubmit = (data) => {




        // check all weekend has open and close time
        let check = true;
        weekend.forEach((item) => {
            if (item.open === '' || item.close === '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select open and close time for all days',
                })
                check = false;
            }
        })

        if (check) {
            // add the weekend to the data
            // data.weekend = weekend;

            // add dateRangeState and weekend info to the data
            data.openings = [
                // create a new object for each dateRangeState and add the weekend info and format the data
                ...allDateAndTime.map((item) => {
                    return {

                        start: {
                            ...item.dateRangeState.map(
                                (item) => {
                                    let startday = item.startDate.toISOString().split('T')[0]
                                    // make startday to next day
                                    let startdayDate = new Date(startday);
                                    startdayDate.setDate(startdayDate.getDate() + 1);
                                    console.log(startdayDate)
                                    return startdayDate.toISOString().split('T')[0]
                                }
                            )
                        },
                        end: {
                            ...item.dateRangeState.map(
                                (item) => {
                                    let endday = item.endDate.toISOString().split('T')[0]
                                    // make endday to next day
                                    let enddayDate = new Date(endday);
                                    enddayDate.setDate(enddayDate.getDate() + 1);
                                    console.log(enddayDate)
                                    return enddayDate.toISOString().split('T')[0]
                                    //return item.endDate.toISOString().split('T')[0]
                                }
                            )
                        },

                        open: [
                            // create an array of open and close time for each day

                            ...item.weekend.map((day) => {
                                return {
                                    day: day.day,
                                    opens: day.open,
                                    close: day.close
                                }
                            })

                        ]
                    }
                })

            ]



            data.email = emails;
            data.number = numbers;
            if (email !== '') {
                data.email.push(email);
            }
            if (number !== '') {
                data.number.push(number);
            }

            // console log the data stringified
            console.log(JSON.stringify(data));

            fetch(`${baseURL}/api/create/location`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Location added successfully',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    // clear form
                    reset();
                    // clear state
                    setWeekend([]);
                    setEmails([]);
                    setNumbers([]);
                    setEmail('');
                    setNumber('');
                })


        }

        // clear allDateAndTime state
        setAllDateAndTime([]);
    };


    // set multiple email 
    const addEmail = (e) => {
        if (e.key === " " ||
            e.code === "Space" ||
            e.keyCode === 32) {
            // alert if email is empty or twice the same email
            if (email === !email.includes('@') || !email.includes('.') || emails.includes(email)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please enter a valid email',
                })
            }
            else {
                setEmails([...emails, e.target.value]);
                setEmail('');

            }


        }
    }

    // set multiple number
    const addNumber = (e) => {
        if (e.key === " " ||
            e.code === "Space" ||
            e.keyCode === 32) {
            // alert if number is twice the same number
            if (number === '' || numbers.includes(number)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You can not add the same number twice',
                })
            }

            else {
                setNumbers([...numbers, e.target.value]);
                setNumber('');
            }
        }
    }


    const handleWeekend = (day) => {
        const newWeekend = [...weekend];
        const index = newWeekend.findIndex((item) => item.day === day);
        if (index === -1) {
            newWeekend.push({
                day,
                open: '',
                close: ''
            });
        } else {
            newWeekend.splice(index, 1);
        }
        setWeekend(newWeekend);
    };

    const handleTime = (e, day, type) => {
        const newWeekend = [...weekend];
        let index = newWeekend.findIndex((item) => item.day === day);

        if (index === -1) {
            // add the day
            newWeekend.push({
                day,
                open: '',
                close: ''
            });
            console.log(newWeekend);
            index = newWeekend.findIndex((item) => item.day === day);
        }
        if (type === 'open') {
            newWeekend[index].open = e.target.value;
        } else {
            newWeekend[index].close = e.target.value;
        }
        console.log(newWeekend);
        setWeekend(newWeekend);
    };




    // FOR MODAL

    let [isModalOpen, setIsModalOpen] = useState(false)

    function closeModal() {
        setIsModalOpen(false)
    }

    function openModal(e) {

        // stop submitting the form
        e.preventDefault();


        setIsModalOpen(true)


    }


    // FOR SINGLE DATE RANGE SELECTION

    const [dateRangeState, setDateRangeState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);


    const addNewDateRangeSet = () => {
        addDateAndTime();

        setDateRangeState([
            ...dateRangeState,
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'

            }
        ]);

        console.log(dateRangeState);

        setWeekend([]);
        closeModal();
    }

    // create an empty array for all the date range and weekend data

    const [allDateAndTime, setAllDateAndTime] = useState([]);

    // push the dateRangeState and weekend to the allDateAndTime array

    const addDateAndTime = () => {
        setAllDateAndTime([...allDateAndTime, { dateRangeState, weekend }]);
    }


    useEffect(() => {
        const url = 'https://api.lekkerwinetours.com/api/locationtype/list';
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                setLocationTypeList(data)
                console.log(data)
            })
    }, [])

    const navigate = useNavigate();
    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);

    return (
        <div className='w-5/6 mx-auto'>

            <div className='lg:w-2/3 mx-auto'>
                <div className='flex justify-between items-center'>
                    <h1 className='py-10 text-2xl font-bold'>Add Location</h1>
                    <div><Link to="/all-locations" className='bg-[#f2a413] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-base'>View All</Link></div>
                </div>
                <form>

                    <div className='flex flex-col mb-20'>


                        <div className='flex justify-between flex-col lg:flex-row'>

                            <div className='flex flex-col  lg:w-[48%]'>
                                <label className='py-2 font-semibold' htmlFor="name">Name:</label>
                                <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="name" {...register('name', { required: true })} />
                                {errors.name && errors.name.type === "required" && <span className="text-red-600 italic"><small>Type a name</small></span>}
                            </div>

                            <div className='flex flex-col lg:w-[48%]'>
                                <div className='flex justify-between'>
                                    <label className='py-2 font-semibold' htmlFor="type">Location Type:</label>
                                    <Link to="/add-location-type"><button className='bg-[#f2a413] px-4 text-sm text-white rounded' style={{height:'30px'}}>Add</button></Link>
                                </div>
                                
                                <select className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="type" {...register('loc_type', { required: true })}>
                                    <option value="">Select location type</option>
                                    {locationTypeList.map((locationType) => (
                                        <option key={locationType.id} value={locationType.name}>{locationType.name}</option>
                                    ))}
                                </select>
                                {errors.loc_type && errors.loc_type.type === "required" && <span className="text-red-600 italic"><small>Select location type</small></span>}
                            </div>
                        </div>


                        {/* <div className='flex justify-between flex-col lg:flex-row'>

                            <div className='flex flex-col  lg:w-[48%]'>
                                <label className='py-2' htmlFor="opendays">Location open days:</label>
                                <MultiSelect
                                    required
                                    options={DateOptions}
                                    value={selected}
                                    onChange={setSelected}
                                    labelledBy={"Select"}
                                />

                            </div>

                            <div className='flex flex-col lg:w-[48%]'>

                            </div>
                        </div> */}

                        <h1 className='mt-10 font-semibold'>Select available days and times for this location:</h1>


                        <div>
                            {
                                // show the date range and weekend 
                                allDateAndTime.map((item, index) => (
                                    <div key={index} className='flex flex-col lg:flex-row justify-between mt-10 bg-gray-100 rounded-lg p-7'>
                                        <div className='flex flex-col lg:w-[48%]'>
                                            <h1 className='font-semibold'>Date Range:</h1>
                                            <div className='flex flex-col'>
                                                {
                                                    item.dateRangeState.map((item, index) => (
                                                        <div key={index} className='flex flex-col lg:flex-row justify-between'>
                                                            <div className='flex flex-col lg:w-[48%]'>
                                                                <label className='py-2' htmlFor="start">Start Date:</label>
                                                                {item.startDate && <p className='py-3 px-3 outline-none bg-white mb-3 rounded-md'>{item.startDate.toLocaleDateString()}</p>}
                                                            </div>
                                                            <div className='flex flex-col lg:w-[48%]'>
                                                                <label className='py-2' htmlFor="end">End Date:</label>
                                                                {item.endDate && <p className='py-3 px-3 outline-none bg-white mb-3 rounded-md'>{item.endDate.toLocaleDateString()}</p>}
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='flex flex-col lg:w-[48%]'>
                                            <h1 className='font-semibold'>Weekdays:</h1>

                                            <div className='flex flex-col'>
                                                {
                                                    item.weekend.map((item, index) => (
                                                        <div key={index} className='flex flex-col lg:flex-row justify-between'>
                                                            <div className='flex flex-col lg:w-[30%]'>
                                                                <label className='py-2' htmlFor="start">Day:</label>
                                                                <p className='py-3 px-3 outline-none bg-white mb-3 rounded-md'>{item.day}</p>
                                                            </div>
                                                            <div className='flex flex-col lg:w-[30%]'>
                                                                <label className='py-2' htmlFor="end">Opening:</label>
                                                                <p className='py-3 px-3 outline-none bg-white mb-3 rounded-md'>{item.open}</p>
                                                            </div>
                                                            <div className='flex flex-col lg:w-[30%]'>
                                                                <label className='py-2' htmlFor="end">Closing:</label>
                                                                <p className='py-3 px-3 outline-none bg-white mb-3 rounded-md'>{item.close}</p>
                                                            </div>
                                                        </div>
                                                    ))

                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))


                            }
                        </div>






                        <button
                            onClick={(e) => openModal(e)}
                            className='w-[250px] py-3 px-5 bg-[#f2a413] text-white rounded-md my-10'
                        >
                            <span className='text-lg font-bold'> +  </span>
                            Add days and time
                        </button>





                        <div className='flex justify-between flex-col lg:flex-row'>
                            <div className='flex flex-col lg:w-[48%]'>
                                <label className='py-2 font-semibold' htmlFor="firstname">Contact name: First name</label>
                                <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="firstname" {...register('fname', { required: true })} />
                                {errors.firstname && errors.firstname.type === "required" && <span className="text-red-600 italic"><small>Type firstname</small></span>}
                            </div>

                            <div className='flex flex-col lg:w-[48%]'>
                                <label className='py-2 font-semibold' htmlFor="lastname">Contact name: Last name</label>
                                <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="lastname" {...register('lname', { required: true })} />
                                {errors.lastname && errors.lastname.type === "required" && <span className="text-red-600 italic"><small>Type lastname</small></span>}
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <label className='py-2 font-semibold' htmlFor="address">Address (Google map link): </label>
                            <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="address" {...register('address', { required: true })} />
                            {errors.address && errors.address.type === "required" && <span className="text-red-600 italic"><small>Type a name</small></span>}
                        </div>

                        <div className='flex justify-between flex-col lg:flex-row'>
                            <div className='flex flex-col lg:w-[48%]'>
                                <label className='pt-2 font-semibold' htmlFor="email">Email: </label>
                                <p className='font-normal text-gray-500 pb-2 text-sm'>(Press Spacebar to add more)</p>
                                {emails.map((email, index) => {
                                    return <span className='text-xs' key={index}
                                    onClick={() => {
                                        const newEmails = emails.filter((e, i) => i !== index)
                                        setEmails(newEmails)
                                    }}
                                    >{email},</span>
                                })}
                                <input onKeyPress={(e) => addEmail(e)}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="email" />{/*...register('email', { required: true })*/}
                                {/* {errors.email && errors.email.type === "required" && <span className="text-red-600 italic"><small>Type a name</small></span>} */}
                            </div>

                            <div className='flex flex-col lg:w-[48%]'>
                                <label className='pt-2 font-semibold' htmlFor="phone">Phone: </label>
                                <p className='text-sm pb-2 text-gray-400'><span className='font-normal text-gray-500 text-sm'>(Include country code & Press Spacebar to add more)</span></p>
                                {numbers.map((number, index) => {
                                    return <span className='text-xs' key={index}
                                    onClick={() => {
                                        const newNumbers = numbers.filter((e, i) => i !== index)
                                        setNumbers(newNumbers)
                                    }}
                                    >{number}</span>
                                })}
                                <input onKeyPress={(e) => addNumber(e)}
                                    onChange={(e) => setNumber(e.target.value)}
                                    value={number}
                                    type="number" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="phone" /> {/*...register('number', { required: true })} */}
                                {/* {errors.phone && errors.phone.type === "required" && <span className="text-red-600 italic"><small>Type a phone number</small></span>} */}
                            </div>
                        </div>

                        <div className='flex justify-between flex-col lg:flex-row'>
                            <div className='flex flex-col lg:w-[48%]'>
                                <label htmlFor="avgstoptime" className='py-2 font-semibold'>Avg. stop time (In minutes):</label>
                                <input type="number" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="avgstoptime" {...register('stop_time', { required: true })} />
                                {errors.avgstoptime && errors.avgstoptime.type === "required" && <span className="text-red-600 italic"><small>Type an avg. stop time</small></span>}
                            </div>

                            <div className='flex flex-col lg:w-[48%]'>
                                <label htmlFor="coupon" className='py-2 font-semibold'>Coupon code:</label>
                                <input className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="coupon" {...register('coupon_code')} />
                            </div>
                        </div>


                        <div className='flex flex-col'>
                            <label className='py-2 font-semibold' htmlFor="notes">Notes:</label>
                            <textarea className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="notes" {...register('notes')} />
                        </div>

                        <input onClick={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)} className='bg-[#f2a413] text-white px-7 cursor-pointer py-3 rounded mt-4 hover:bg-[#f2a413] transition duration-200' type="button" value="Add location" />

                    </div>
                </form>
            </div>




            {/* __________________MODAL___________________ */}

            <>

                <Transition appear show={isModalOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-5 lg:p-10 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg mb-10 font-medium leading-6 text-gray-900"
                                        >
                                            Add date range and available days:
                                        </Dialog.Title>
                                        <div className='flex flex-col my-5'>

                                            <div>
                                                <div className='mb-5'>
                                                    <label className='py-2 font-semibold' htmlFor="date">Select date range:</label>
                                                </div>

                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={item => setDateRangeState([item.selection])}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={dateRangeState}
                                                />

                                            </div>

                                            <div className='mb-5'>
                                                <label className='py-2 font-semibold' htmlFor="date">Select available days & time:</label>
                                            </div>

                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isMonday" id="isMonday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Monday')}
                                                    checked={weekend.find((item) => item.day === 'Monday') ? true : false} />


                                                <select name="" id="" className='py-3 lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Monday">Monday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Monday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Monday') ? weekend.find((item) => item.day === 'Monday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Monday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Monday') ? weekend.find((item) => item.day === 'Monday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }


                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isTuesday" id="Tuesday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Tuesday')}
                                                    checked={weekend.find((item) => item.day === 'Tuesday') ? true : false} />


                                                <select name="" id="" className='py-3 lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Tuesday">Tuesday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Tuesday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Tuesday') ? weekend.find((item) => item.day === 'Tuesday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Tuesday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Tuesday') ? weekend.find((item) => item.day === 'Tuesday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }


                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isWednesday" id="isWednesday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Wednesday')}
                                                    checked={weekend.find((item) => item.day === 'Wednesday') ? true : false} />


                                                <select name="" id="" className='py-3  lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Wednesday">Wednesday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Wednesday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Wednesday') ? weekend.find((item) => item.day === 'Wednesday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Wednesday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Wednesday') ? weekend.find((item) => item.day === 'Wednesday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }


                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isThursday" id="isThursday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Thursday')}
                                                    checked={weekend.find((item) => item.day === 'Thursday') ? true : false} />


                                                <select name="" id="" className='py-3 lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Thursday">Thursday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Thursday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Thursday') ? weekend.find((item) => item.day === 'Thursday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Thursday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Thursday') ? weekend.find((item) => item.day === 'Thursday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }


                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isFriday" id="isFriday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Friday')}
                                                    checked={weekend.find((item) => item.day === 'Friday') ? true : false} />


                                                <select name="" id="" className='py-3 lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Friday">Friday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Friday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Friday') ? weekend.find((item) => item.day === 'Friday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Friday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Friday') ? weekend.find((item) => item.day === 'Friday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isSaturday" id="isSaturday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Saturday')}
                                                    checked={weekend.find((item) => item.day === 'Saturday') ? true : false} />


                                                <select name="" id="" className='py-3 px-5 lg:w-[200px] outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Saturday">Saturday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Saturday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Saturday') ? weekend.find((item) => item.day === 'Saturday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Saturday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Saturday') ? weekend.find((item) => item.day === 'Saturday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className='flex flex-col lg:flex-row justify-between mb-5'>
                                                <input type="checkbox" name="isSunday" id="isSunday" className='h-10 w-10 checkbox checkbox-lg my-2 lg:m-0' onChange={() => handleWeekend('Sunday')}
                                                    checked={weekend.find((item) => item.day === 'Sunday') ? true : false} />


                                                <select name="" id="" className='py-3 lg:w-[200px] px-5 outline-none bg-gray-100 mb-3 rounded-md'>
                                                    <option value="Sunday">Sunday</option>
                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Sunday', 'open')}
                                                    value={weekend.find((item) => item.day === 'Sunday') ? weekend.find((item) => item.day === 'Sunday').open : ''}>
                                                    <option value="">Pick an opening time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }

                                                </select>

                                                <select name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={(e) => handleTime(e, 'Sunday', 'close')}
                                                    value={weekend.find((item) => item.day === 'Sunday') ? weekend.find((item) => item.day === 'Sunday').close : ''}>
                                                    <option value="">Pick a closing time</option>
                                                    {
                                                        timeLoop.map((time, index) => {
                                                            return <option key={index} value={time.value}>{time.label}</option>
                                                        })
                                                    }


                                                </select>
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-[#f2a413] px-10 py-2 text-sm font-medium text-white hover:bg-[#f2a413] transition duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-offset-2"
                                                onClick={addNewDateRangeSet}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>


        </div>
    );
};

export default AddLocation;