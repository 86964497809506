import React, { useState } from 'react';
import { useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import List from '../components/shared/DragAndDrop';
import MainMaps from '../components/shared/Map';

const PlanATour = () => {

    const createURL = 'https://api.lekkerwinetours.com/api/tour/calculate';

    // get the token from local storage
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const [showMap, setShowMap] = useState(false);
    const [miniMapData, setMiniMapData] = useState({});
    const [calculateMessage, setCalculateMessage] = useState([]);

    const [name, setName] = React.useState("");
    const [start_date_time, setStart_date_time] = React.useState(new Date());
    const [vehicle_id, setVehicle_id] = React.useState("");
    //console.log(value);

    const { register, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = (data) => {

        // console.log(data);
        // console.log(1);
    };

    // create empty array to store the selected places
    const [selectedPlaces, setSelectedPlaces] = useState([]);
    const [allPoints, setAllPoints] = useState([]);

    // create a function to add the selected places to the array
    const addPlace = (e) => {
        setSelectedPlaces([...selectedPlaces, e.target.value]);
        console.log(e.target.value);
    };

    //console.log(selectedPlaces);



    const removeItemFromSelectedPlaces = (place) => {
        const newSelectedPlaces = selectedPlaces.filter((item) => item !== place);
        setSelectedPlaces(newSelectedPlaces);
    };

    // calculate Route click status

    const [calculateRouteClick, setCalculateRouteClick] = useState(false);




    const calculateRoute = () => {

        setCreateTourClick(true);

        //console.log(miniMapData);

        setCalculateRouteClick(true);


        let myObj = {}

        let origin = locations.locations.find((item) => item.name === selectedPlaces[0]);
        let originAddress = origin.address; // "https://www.google.com/maps/place/Dhaka/@23.7805733,90.2791959,11z/data=!3m1!4b1!4m6!3m5!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.810332!4d90.4125181!16zL20vMGZuYjQ"
        const originLat = originAddress?.split('@')[1].split(',')[0];
        const originLng = originAddress?.split('@')[1].split(',')[1];
        myObj.origin = {
            name: origin.name,
            id: origin.id,
            lat: originLat,
            lng: originLng,
        }

        let destination = locations.locations.find((item) => item.name === selectedPlaces[selectedPlaces.length - 1]);
        let destinationAddress = destination.address;
        const destinationLat = destinationAddress?.split('@')[1].split(',')[0];
        const destinationLng = destinationAddress?.split('@')[1].split(',')[1];
        myObj.destination = {
            name: destination.name,
            id: destination.id,
            lat: destinationLat,
            lng: destinationLng,
        }

        let waypoints = selectedPlaces.slice(1, selectedPlaces.length - 1).map((item) => {
            let waypoint = locations.locations.find((location) => location.name === item);
            let waypointAddress = waypoint.address;
            const waypointLat = waypointAddress?.split('@')[1].split(',')[0];
            const waypointLng = waypointAddress?.split('@')[1].split(',')[1];
            return {
                name: waypoint.name,
                id: waypoint.id,
                lat: waypointLat,
                lng: waypointLng,
            }
            //return locations.locations.find((location) => location.name === item);
        });
        myObj.waypoints = waypoints;

        setMiniMapData(myObj);

        setShowMap(true);
        setCalculateRouteClick(false);
        // scrolldown 400px smooth
        window.scrollBy({
            top: 500,
            left: 0,
            behavior: 'smooth'
        });
    };


    // call api to get all the locations
    const [locations, setLocations] = useState([]);

    React.useEffect(() => {
        fetch('https://api.lekkerwinetours.com/api/tour/plan', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => res.json())
            .then(data => {
                setLocations(data);
            })
    }, []);

    console.log(locations);

    useEffect(() => {
        //console.log(selectedPlaces);
    }, [selectedPlaces]);

    const handleSelectedPlaces = (data) => {
        setSelectedPlaces(data);
    };


    React.useEffect(() => {
        // check token
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/');
        }
    }, []);


    // state for clicking the create tour button
    const [createTourClick, setCreateTourClick] = useState(false);






    return (
        <div className='w-5/6 mx-auto'>
            <div className='mt-10 flex-col flex lg:w-2/3 mx-auto'>
                <h1 className='py-7 text-2xl font-bold'>Plan a Tour</h1>
                <label className='py-2 mt-3' htmlFor="maxpassenger">Date & Time:</label>
                <DateTimePicker onChange={setStart_date_time} value={start_date_time} />
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className='flex flex-col'>
                        <label className='py-2' htmlFor="name">Name:</label>
                        <input required className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' id="name" type="text" onChange={e => setName(e.target.value)} />
                        {errors.name && errors.name.type === "required" && <span className="text-red-600 italic"><small>Type a name</small></span>}
                    </div>

                    <div className='flex flex-col'>
                        <label className='py-2 mt-3' htmlFor="maxpassenger">Vehicle:</label>
                        <select required name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md' onChange={e => setVehicle_id(e.target.value)}>
                            <option value="">Select a vehicle</option>
                            {
                                locations?.vehicles?.map((vehicle) => {
                                    //console.log(vehicle);
                                    return (
                                        <option value={vehicle.id}>{vehicle.model}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div className='flex flex-col'>
                        <label className='py-2 mt-3' htmlFor="maxpassenger">Pickup/Drop off location:</label>
                        <select required onChange={(e) => addPlace(e)} name="" id="" className='py-3 px-3 outline-none bg-gray-100 mb-3 rounded-md'>
                            <option value="">Select a location</option>
                            {
                                locations?.locations?.map((location) => {
                                    return (
                                        <option value={location.name}>{location.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>

                    <div>
                        <List places={selectedPlaces} removeItemFromSelectedPlaces={removeItemFromSelectedPlaces} handleSelectedPlaces={handleSelectedPlaces} />
                    </div>



                    <div className='flex justify-center'>
                        <button onClick={calculateRoute} type='submit' className='bg-[#f2a413] rounded text-white px-7 py-2 my-10'>Calculate route</button>
                    </div>

                    {showMap &&
                        <div>
                            <div className='py-10 text-center'>
                                <MainMaps tourClick={createTourClick} setTourCick={setCreateTourClick} url={createURL} isRouting={calculateRouteClick} data={miniMapData} setAllPoints={setAllPoints} calculateMessage={calculateMessage}
                                    name={name} start_date_time={start_date_time} vehicle_id={vehicle_id}
                                    locations={locations} />
                            </div>
                        </div>}
                </form>
            </div>
        </div>
    );
};

export default PlanATour;