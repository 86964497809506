import React from "react";

var placeholder = document.createElement("li");
placeholder.className = "placeholder";

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  // check props change
  componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    this.setState({ ...nextProps });
  }

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }
  dragEnd(e) {
    this.dragged.style.display = 'flex';
    this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.places;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if (from < to) to--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({ places: data });
    this.props.handleSelectedPlaces(data);
    //console.log(data);
  }
  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = "none";
    if (e.target.className === 'placeholder') return;
    if (e.target.className === 'list-item w-[90%]') return;
    this.over = e.target;
    if (this.over.tagName === 'UL') return;
    // // print the element mouse is over
    //console.log(e.target);
    // replace element with placeholder
    e.target.parentNode.insertBefore(placeholder, e.target);
  }


  render() {
    var listItems = this.state.places.map((item, i) => {
      //console.log(item);
      return (
        <div className="flex"
          data-id={i}
          key={i}
          draggable='true'
          onDragEnd={this.dragEnd.bind(this)}
          onDragStart={this.dragStart.bind(this)}>
          <div className="list-item w-[90%]">
            {item}
          </div>
          <div onClick={this.props.removeItemFromSelectedPlaces.bind(this, item)} className="w-[10%] py-2 my-1 bg-red-500 text-center text-white">
            X
          </div>
        </div>
      )
    });
    return (
      <ul onDragOver={this.dragOver.bind(this)}>
        {listItems}
      </ul>
    )
  }
}

export default List;
